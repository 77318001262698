import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";
import parse from "html-react-parser";
import {
  Attachments,
  ProfileAvatar,
  Loading,
  FormRow,
  FormRowSelect,
  Dropzone,
  DescriptionBox,
  // CustumTooltip,
} from "../Common";
import { useAppContext } from "../../context/context/appContext";
import Worklog from "./Worklog";
import {
  capitalizeDescription,
  capitalizeFirstLetter,
  getInitials,
} from "../../utils/getInitialName";
import { useDispatch, useSelector } from "react-redux";
import ManageGoalModal from "../Goals/ManageGoalModal";
import {
  getGoalsProject,
  openWorklogModal,
  updateTask,
  closeTaskModal,
  deleteTask,
  archiveTask,
  getWorklogTask,
  openManageGoalModal,
  openCodeReviewModal,
  setIsReviewId,
  checkStatus,
} from "../../redux";
import PagesIcon from "@mui/icons-material/Pages";
import { TaskWrapper } from "../../assets/styles/Project";
import { toast } from "react-toastify";
import {
  // DriveFileRenameOutlineSharpIcon,
  FiberManualRecordRoundedIcon,
  AddSharpIcon,
  DeleteIcon,
} from "../../assets/icons";
import { putResource } from "../../utils/s3Resources";
import ArchiveOutlined from "@mui/icons-material/ArchiveOutlined";
import AllCodeReview from "./AllCodeReview";

const initialState = {
  _id: "",
  title: "",
  requirement: "",
  acceptanceCriteria: "",
  unitTesting: "",
  estimatedHours: 0,
  businessValue: 0,
  reportTo: null,
  assignedTo: null,
  priority: "",
  startDate: moment(new Date()).format("YYYY-MM-DD"),
  endDate: moment(new Date()).format("YYYY-MM-DD"),
  labels: "",
  status: "",
  category: "",
  goal: "",
  goalTitle: "",
};

const TaskModal = () => {
  const { user, getSingleTask, accessList } = useAppContext();
  const { selectedProject } = useSelector((state) => state.project);
  const { editingTask, isTaskEditing, isTaskModalOpen } = useSelector(
    (state) => state.task
  );
  const { status } = useSelector((state) => state.codeReview);

  const { goals } = useSelector((state) => state.goal);
  const dispatch = useDispatch();
  const [task, setTask] = useState(null);
  const [values, setValues] = useState(initialState);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [goalsList, setGoalsList] = useState(null);
  const [showAssignTo, setShowAssignTo] = useState(false);
  const [showreportTo, setShowreportTo] = useState(false);
  const [assign, setAssign] = useState("");
  const [report, setReport] = useState("");
  const [titleOpen, setTitleOpen] = useState(false);
  const [taskcreatedby, setTaskcreatedby] = useState("");
  const [taskcreatedby_time, setTaskcreatedbytime] = useState("");
  const [taskupdatedbytime, setTaskupdatedbytime] = useState("");
  const [requirementOpen, setRequirementOpen] = useState(false);
  const [acceptCriteriaOpen, setAcceptCriteriaOpen] = useState(false);
  const [unitTestingOpen, setUnitTestingOpen] = useState(false);
  const currentDate = new Date();

  // Format the date as YYYY-MM-DD, which is the expected format for an input of type "date"
  const currentDateString = currentDate.toISOString().split("T")[0];

  const onDrop = async (acceptedFiles) => {
    for (let index = 0; index < acceptedFiles.length; index++) {
      setIsLoadingFile(true);

      const form = new FormData();
      form.append("id", editingTask._id);
      form.append("files", acceptedFiles[index]);
      await putResource(form);

      setIsLoadingFile(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (values.estimatedHours === 0 || values.estimatedHours === undefined) {
      toast.error("fill estimated hours");
      return;
    }
    dispatch(updateTask(values));
    setTitleOpen(false);
    setRequirementOpen(false);
    setAcceptCriteriaOpen(false);
    setUnitTestingOpen(false);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangeDescription = (e) => {
    let data = e;
    setValues({ ...values, requirement: data });
  };

  const handleChangeTesting = (e) => {
    let data = e;
    setValues({ ...values, unitTesting: data });
  };

  const handleChangeCriteria = (e) => {
    let data = e;
    setValues({ ...values, acceptanceCriteria: data });
  };
  const handleGoalChange = (e) => {
    const goalId = goals.find((i) => i.title === e.target.value);
    setValues({ ...values, goal: goalId._id, goalTitle: e.target.value });
  };

  useEffect(() => {
    if (editingTask !== null) {
      getSingleTask(editingTask._id).then((data) => {
        const createdBy = data.createdby;
        const time = data.task.createdAt;
        const updatedtime = data.task.updatedAt;
        const formattedTime = moment(time).format("MMMM Do YYYY, h:mm:ss a");
        const formattedupdateTime = moment(updatedtime).format(
          "MMMM Do YYYY, h:mm a"
        );
        const createdbytime = createdBy + " On " + formattedTime;

        setTaskcreatedby(createdBy);
        setTaskcreatedbytime(createdbytime);
        setTaskupdatedbytime(formattedupdateTime);

        setTask(data.task);
        if (data.task.goal) {
          const goalTitle = goals.find((i) => i._id === data.task.goal);

          if (goalTitle) {
            setValues({ ...values, goalTitle: goalTitle.title });
          }
        }
      });
    }
    if (editingTask) {
      setTask(editingTask);
      dispatch(getWorklogTask(editingTask._id));

      setShowAssignTo(true);
      setShowreportTo(true);
      if (editingTask.goal) {
        const goalTitle = goals.find((i) => i._id === editingTask.goal);

        if (goalTitle) {
          setValues({ ...values, goalTitle: goalTitle.title });
        }
      }
      if (!editingTask.assignedTo) {
        setAssign("");
      }
      if (!editingTask.reportTo) {
        setReport("");
      }
      if (editingTask.assignedTo) {
        setShowAssignTo(false);
      }
      if (editingTask.reportTo) {
        setShowreportTo(false);
      }

      dispatch(getGoalsProject({ project: selectedProject._id }));
      const goalList = goals && goals.map((i) => i.title);
      setGoalsList(goalList);
    }

    setTitleOpen(false);
    setRequirementOpen(false);
    setAcceptCriteriaOpen(false);
    setUnitTestingOpen(false);
  }, [editingTask]);

  useEffect(() => {
    //  const duedate= moment(task.endDate).format("YYYY-MM-DD")
    //   const day=Math.ceil(task.estimatedHours / 8)

    // const minDate = new Date(duedate);
    // minDate.setDate(minDate.getDate() + Math.ceil(task.estimatedHours / 8));
    if (task) {
      const duedate = moment(task.endDate).format("YYYY-MM-DD");
      const day = Math.floor(task.estimatedHours / 9);

      const minDate = new Date(duedate);

      const estimatedDays = day;
      for (let i = 0; i < estimatedDays; i++) {
        minDate.setDate(minDate.getDate() + day);
        // Add one day to the date
        // If the current day is Friday, skip to the next Monday
        if (minDate.getDay() === 5) {
          // Friday (Sunday is 0, Saturday is 6)
          minDate.setDate(minDate.getDate() + 3); // Skip Saturday and Sunday
        }
      }

      setValues({
        ...values,
        _id: task._id,
        title: task.title,
        requirement: task.requirement,
        status: task.status,
        priority: task.priority,
        startDate: moment(task.startDate).format("YYYY-MM-DD"),
        endDate: moment(task.endDate).format("YYYY-MM-DD"),
        category: task.category,
        goal: task.goal,
        assignedTo: task.assignedTo,
        reportTo: task.reportTo,
        acceptanceCriteria: task.acceptanceCriteria,
        businessValue: task.businessValue,
        estimatedHours: task.estimatedHours,
        unitTesting: task.unitTesting,
      });
    }
  }, [task]);

  return (
    editingTask && (
      <TaskWrapper onDrop={(files) => onDrop(files)}>
        <Modal
          size="lg"
          show={isTaskModalOpen}
          onHide={() => {
            dispatch(closeTaskModal());
          }}
          dialogClassName="modal-10w"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "20px" }}>
              <span className="Upper grey f-12 ">
                <PagesIcon fontSize="15" />
                {getInitials(selectedProject.title)}-{editingTask.key}{" "}
              </span>
            </Modal.Title>
            <div
              className="d-flex"
              style={{ gap: "5px", color: "var(--grey-500)" }}
            >
              {accessList.includes("DELETE_TASK") && (
                <DeleteIcon
                  onClick={(e) => {
                    dispatch(deleteTask(editingTask._id));
                  }}
                />
              )}
              {accessList.includes("ARCHIVE_TASK") && (
                <ArchiveOutlined
                  onClick={(e) => {
                    dispatch(archiveTask({ id: task._id }));
                  }}
                />
              )}
            </div>
          </Modal.Header>
          <Modal.Body //style={{ height: "500px", overflowY: "scroll" }}
          >
            <TaskWrapper>
              <div className="tw-flex">
                <div
                  className="tw-gap-4 tw-flex tw-flex-col //tw-bg-green-200 
                tw-w-[60%] tw-p-4"
                >
                  <div>
                    <label htmlFor="title">
                      Title
                      {/* {!titleOpen && (
                      <CustumTooltip
                        text={"Edit title of task"}
                        children={
                          <DriveFileRenameOutlineSharpIcon
                            onClick={() => setTitleOpen(true)}
                          />
                        }
                        position={"right"}
                        id={`task-tooltip`}
                      />
                    )} */}
                    </label>
                    {titleOpen ? (
                      <>
                        <FormRow
                          type="text"
                          name="title"
                          value={values.title}
                          handleChange={handleChange}
                          placeholder="Enter Title"
                        />
                        <div className="edit-buttons">
                          <button
                            type="cancel"
                            className="btn-blockn"
                            onClick={() => setTitleOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn-blockn"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    ) : (
                      <div
                        className="placeTaken"
                        onClick={() => setTitleOpen(true)}
                      >
                        {values.title ? (
                          <div>{capitalizeFirstLetter(values.title)}</div>
                        ) : (
                          " Data is not available/No data "
                        )}
                      </div>
                    )}
                  </div>
                  <div className="">
                    <label htmlFor="requirment">
                      Description{" "}
                      {/* {!requirementOpen && (
                      <CustumTooltip
                        text={"Edit requirement of task"}
                        children={
                          <DriveFileRenameOutlineSharpIcon
                            onClick={() => setRequirementOpen(true)}
                          />
                        }
                        position={"right"}
                        id={`req-tooltip`}
                      />
                    )} */}
                      {/* <Tooltip id="my-tooltip" place="bottom" className="toolttp" /> */}
                    </label>
                    {requirementOpen ? (
                      <>
                        <DescriptionBox
                          handleChangeDescription={handleChangeDescription}
                          value={values.requirement}
                        />
                        <div className="edit-buttons">
                          <button
                            type="cancel"
                            className="btn-blockn"
                            onClick={() => setRequirementOpen(false)}
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className="btn-blockn"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    ) : (
                      <div
                        className="placeTaken"
                        onClick={() => setRequirementOpen(true)}
                      >
                        <div
                          style={{ wordBreak: "break-word", padding: "20px" }}
                        >
                          {values.requirement
                            ? parse(capitalizeDescription(values.requirement))
                            : " Data is not available/No data "}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <label htmlFor="acceptanceCriteria">
                      Acceptance Criteria{" "}
                      {/* {!acceptCriteriaOpen && (
                      <CustumTooltip
                        text={"Edit acceptance criteria of task"}
                        children={
                          <DriveFileRenameOutlineSharpIcon
                            onClick={() => setAcceptCriteriaOpen(true)}
                          />
                        }
                        position={"right"}
                        id={`accept-tooltip`}
                      />
                    )} */}
                    </label>
                    {acceptCriteriaOpen ? (
                      <>
                        <DescriptionBox
                          handleChangeDescription={handleChangeCriteria}
                          value={values.acceptanceCriteria}
                        />
                        <div className="edit-buttons">
                          <button
                            type="cancel"
                            className="btn-blockn"
                            onClick={() => setAcceptCriteriaOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn-blockn"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    ) : (
                      <div
                        className="placeTaken"
                        onClick={() => setAcceptCriteriaOpen(true)}
                      >
                        <div
                          style={{ wordBreak: "break-word", padding: "20px" }}
                        >
                          {" "}
                          {values.acceptanceCriteria
                            ? parse(
                                capitalizeDescription(values.acceptanceCriteria)
                              )
                            : "  Data is not available/No data "}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <label htmlFor="unitTesting">
                      Unit Testing{" "}
                      {/* {!unitTestingOpen && (
                      <CustumTooltip
                        text={"Edit unit testing of task"}
                        children={
                          <DriveFileRenameOutlineSharpIcon
                            onClick={() => setUnitTestingOpen(true)}
                          />
                        }
                        position={"right"}
                        id={`unit-tooltip`}
                      />
                    )} */}
                    </label>
                    {unitTestingOpen ? (
                      <>
                        <DescriptionBox
                          handleChangeDescription={handleChangeTesting}
                          value={values.unitTesting}
                        />
                        <div className="edit-buttons">
                          <button
                            type="cancel"
                            className="btn-blockn"
                            onClick={() => setUnitTestingOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn-blockn"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    ) : (
                      <div
                        className="placeTaken"
                        onClick={() => setUnitTestingOpen(true)}
                      >
                        <div
                          style={{ wordBreak: "break-word", padding: "20px" }}
                        >
                          {values.unitTesting
                            ? parse(capitalizeDescription(values.unitTesting))
                            : "  Data is not available/No data"}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <label htmlFor="attachment">Attachments</label>
                    <Dropzone
                      onDrop={(files) => onDrop(files)}
                      loading={isLoadingFile}
                    />
                    <Attachments
                      attachments={editingTask.attachments}
                      task={true}
                    />
                  </div>
                </div>

                <div
                  className="//tw-bg-gray-400
                 tw-w-[40%] tw-p-4 tw-gap-2 tw-flex tw-flex-col"
                >
                  <div className="tw-flex tw-gap-2">
                    <div className="tw-w-[50%]">
                      <FormRowSelect
                        labelText="status"
                        name="status"
                        value={values.status}
                        list={[...selectedProject.statusCategories]}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="tw-w-[50%]">
                      <label
                        htmlFor={"priority"}
                        className={`form-label ${values.priority}-priority`}
                      >
                        Priority{" "}
                        <FiberManualRecordRoundedIcon className="icon" />
                      </label>
                      <select
                        name={"priority"}
                        value={values.priority}
                        onChange={handleChange}
                        className={`form-select ${values.priority}-priority`}
                      >
                        {["lowest", "low", "medium", "high", "highest"].map(
                          (item, index) => {
                            // Change color based on selection
                            const iconColor = "green";
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="tw-flex tw-gap-2">
                    <div className="tw-w-[50%]">
                      <FormRow
                        type="date"
                        name="startDate"
                        labelText="start date"
                        value={
                          values.startDate
                            ? values.startDate
                            : currentDateString
                        }
                        // value={
                        //   values.status === "in progress" ||
                        //     values.status === "done"
                        //     ? values.startDate
                        //     : currentDateString
                        // }
                        handleChange={handleChange}
                        //  min={values.startDate}
                        placeholder="Start Date"
                      />
                    </div>

                    <div className="tw-w-[50%]">
                      <FormRow
                        type="date"
                        name="endDate"
                        labelText="due date"
                        value={
                          values.endDate ? values.endDate : currentDateString
                        }
                        handleChange={handleChange}
                        //   min={values.endDate}
                        placeholder="Enter Due Date"
                      />
                    </div>
                  </div>
                  <div className="">
                    <FormRow
                      type="text"
                      name="category"
                      labelText="Category"
                      value={values.category}
                      handleChange={handleChange}
                      placeholder="Category"
                    />
                  </div>

                  <div className="tw-flex tw-gap-2">
                    <div className="tw-w-[50%]">
                      <FormRow
                        type="number"
                        name="estimatedHours"
                        labelText="estimated hours"
                        value={values.estimatedHours}
                        handleChange={handleChange}
                        placeholder="Estimated Hours"
                        required={true}
                      />
                    </div>

                    <div className="tw-w-[50%] tw-flex tw-flex-col">
                      <label htmlFor="businessValue">Bussiness Value </label>

                      <input
                        type="text"
                        className="bussiness-value"
                        name="businessValue"
                        value={values.businessValue}
                        onChange={handleChange}
                        placeholder="Business Value"
                      />

                      {/* <span>
                        %
                      </span> */}
                    </div>
                  </div>

                  {/* assign to  */}
                  <div className="tw-flex tw-flex-col">
                    <label className="">Assign To</label>
                    {editingTask.assignedTo &&
                      selectedProject.team.map((item) => {
                        if (item.id === editingTask.assignedTo) {
                          return (
                            <div
                              key={item._id}
                              className="assign"
                              onClick={() => setShowAssignTo(!showAssignTo)}
                            >
                              <ProfileAvatar id={item.id} size="s" />
                              <h5>{item.email}</h5>
                            </div>
                          );
                        }
                      })}
                    {showAssignTo && (
                      <div className="lowerCase">
                        {" "}
                        <FormRowSelect
                          name="assign"
                          value={assign}
                          list={[
                            "select",
                            ...selectedProject.team.map((item) => item.email),
                          ]}
                          handleChange={(e) => {
                            setAssign(e.target.value);
                            const user = selectedProject.team.find(
                              (item) => item.email === e.target.value
                            );
                            setValues({
                              ...values,
                              assignedTo: user.id,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>

                  {/* report to */}
                  <div className="tw-flex tw-flex-col">
                    <label className="">Report To</label>
                    {editingTask.reportTo &&
                      selectedProject.team.map((item) => {
                        if (item.id === editingTask.reportTo) {
                          return (
                            <div
                              key={item._id}
                              className="assign"
                              onClick={() => setShowreportTo(!showreportTo)}
                            >
                              <ProfileAvatar id={item.id} size="s" />
                              <h5>{item.email}</h5>
                            </div>
                          );
                        }
                      })}
                    {showreportTo && (
                      <div className="lowerCase">
                        <FormRowSelect
                          name="report"
                          value={report}
                          list={[
                            "select",
                            ...selectedProject.team.map((item) => item.email),
                          ]}
                          handleChange={(e) => {
                            setReport(e.target.value);
                            const user = selectedProject.team.find(
                              (item) => item.email === e.target.value
                            );
                            setValues({
                              ...values,
                              reportTo: user.id,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {/* <GoalModal /> */}
                  <div className="tw-flex tw-flex-col">
                    {goalsList && (
                      <>
                        <label htmlFor="goal" className="cont">
                          goal{" "}
                          {/* {user.role === "admin" ? (
                          <AddSharpIcon
                            color="white"
                            fontSize="20px"
                            onClick={() => dispatch(openManageGoalModal())}
                          />
                        ) : null} */}
                        </label>
                        <FormRowSelect
                          value={values.goalTitle}
                          list={["select", ...goalsList]}
                          name="goal"
                          handleChange={handleGoalChange}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12  t">
                <div className="btn-groupp ">
                  <div className="share-icon"></div>
                  <div className="d-flex">
                    <button
                      className="btn btn-cancel"
                      type="cancel"
                      onClick={() => {
                        setValues(initialState);
                        dispatch(closeTaskModal());
                      }}
                    >
                      Cancel
                    </button>
                    {/* {user.role === "admin" && (
                        <button
                          className="btn btn-block col-xs-12 col-sm-6 col-md-3"
                          type="submit"
                          onClick={(e) => {
                            dispatch(deleteTask(editingTask._id))
                          }}
                        >
                          delete
                        </button>
                      )} */}
                    {/* <button
                        className="btn btn-block col-xs-12 col-sm-6 col-md-3"
                        type="button"
                        onClick={(e) => {
                          dispatch(archiveTask({ id: task._id }))
                        }}
                      >
                        archive
                      </button> */}
                    <button
                      className="btn btn-block"
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                    >
                      submit
                    </button>
                  </div>
                </div>
                {taskcreatedby ? (
                  <div className="created-by tw-mt-4">
                    <label>Created by: {taskcreatedby_time} </label>
                  </div>
                ) : null}
                <div className="work-logs">
                  <Tabs
                    activeTab="1"
                    className="tabss"
                    ulClassName="work-ul"
                    activityClassName="bg-success"
                    onClick={(event, tab) => console.log(event, tab)}
                  >
                    <Tab title="Work Log" className="mr-3">
                      <button
                        type="button"
                        className="btn btnw"
                        onClick={() => dispatch(openWorklogModal())}
                      >
                        Create log
                      </button>
                      <Worklog />
                    </Tab>
                    <Tab
                      title={
                        <span>
                          Code Review
                          <FiberManualRecordRoundedIcon
                            style={{
                              color:
                                status === 2
                                  ? "#0b9f5c"
                                  : status == 3
                                  ? "red"
                                  : "gray", // Use red for 'fail'
                              marginLeft: "8px", // Add some spacing between the text and the icon
                            }}
                          />
                        </span>
                      }
                      className="mr-3"
                    >
                      <button
                        type="button"
                        className="btn btnw"
                        onClick={() => {
                          dispatch(openCodeReviewModal());
                          dispatch(setIsReviewId(""));
                        }}
                      >
                        Create Review
                      </button>
                      <AllCodeReview />
                    </Tab>
                    <Tab>{/* <div className="mt-3">Comment</div> */}</Tab>
                  </Tabs>
                </div>
              </div>
            </TaskWrapper>
          </Modal.Body>
        </Modal>
        <ManageGoalModal />
      </TaskWrapper>
    )
  );
};

export default TaskModal;
